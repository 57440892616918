import { http, APIRoot, doExec, local } from './request'
import md5 from '../utils/md5'
import XDate from 'xdate'
import qs from 'qs'
import { newGuid } from '../utils/tools'
import des from '../utils/des'

export const getValidCodeImgUrl = () => {
	let evidence = newGuid();
	let url = APIRoot + 'Verify/Image?evidence=' + evidence;
	return { url, evidence };
};
export const sendResetPassCode = (account) => {
	let evidence = newGuid();
	let info = {};
	info.Account = account;
	info.Evidence = evidence;
	return http.post('Account/SendForgetPassSMS', info).then((res) => {
		return evidence;
	});
};

const createKey = (pp, pass) => {
	var keystr = md5(pp + pass);
	return keystr;
};
const getCommKey = (code) => {
	return (code + md5(code)).substr(0, 8);
}

export const loginWithWechat = (code) => {
	return http.post(`Account/LoginWithWeChat`,{exec:'Web',code}).then((res) => {
		localStorage.setItem('UserID', res.data.UserID);
		localStorage.setItem('PP', res.data.PP);
		localStorage.setItem('UserName', res.data.UserName);
		localStorage.setItem('Key', res.data.SecretKey);
		localStorage.setItem('Kind', res.data.Kind);
		return true;
	});
};
export const login = (info) => {
	let body = {};
	body.Evidence = info.Evidence;
	body.Account = info.Account;
	body.VerifyCode = md5(info.VerifyCode.toUpperCase());
	let ts = new XDate().toString('yyyyMMddHHmmss');
	body.TS = ts;
	let str = qs.stringify(body, { arrayFormat: 'brackets' });
	let signStr = md5(str + info.Pass);
	str = str + '&Sign=' + signStr;

	return http.post('Account/Login?' + str, {}).then((res) => {
		localStorage.setItem('UserID', res.data.UserID);
		localStorage.setItem('PP', res.data.PP);
		localStorage.setItem('UserName', res.data.UserName);
		localStorage.setItem('Key', res.data.SecretKey);
		localStorage.setItem('Kind', res.data.Kind);
		return true;
	});
};
export const logout = () => {
	localStorage.removeItem('Key');
	let pp = localStorage.getItem('PP');
	return http.get('Account/Logout?pp=' + pp).then(() => {
		localStorage.clear();
	});
};


export const IsValidImageCode = (evidence, verifyCode) => {
	let info = {};
	info.Evidence = evidence;
	info.VerifyCode = md5(verifyCode.toUpperCase());
	return http.post('Verify/IsRightCode', info).then((res) => { return res.data });
};

