 <template>
    <div id="app" style="height: calc(100% - 28px)">
        <transition name="bounce">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
export default {
  name: 'app'
}
</script>
