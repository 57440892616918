<template>
  <div style="text-align: right; height: 100%; color: #ccc">
    <div style="float: left; padding: 10px">
      <img src="/static/logo.png" style="margin: 0 auto; height: 40px; padding: 4px" />
    </div>
    <div style="float: left">
      <span style="font-size: 26px; line-height: 60px; margin-right: 10px">{{ appName }}</span>
      <el-button v-if="appID" @click="gotoFirstPage" type="text"><svg-icon iconClass="exit" style="color: #aaa; font-size: 14px" /></el-button>
    </div>
    <el-dropdown style="margin-right: 20px">
      <span class="el-dropdown-link" style="color: #ccc; line-height: 60px">
        {{ userName }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" style="margin-top: -16px">
        <el-dropdown-item @click.native="onMyApp">我的应用</el-dropdown-item>
        <el-dropdown-item @click.native="onPersonCenter">个人中心</el-dropdown-item>
        <el-dropdown-item divided @click.native="logout">退出系统</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  props:{
    showApp:Boolean
  },
  computed: {
    userName() {
      return localStorage.getItem('UserName')
    }
  },
  data() {
    return {
      appName: '智能精准灌溉平台',
      appID:null
    }
  },
  created() {
    if(this.showApp){
    this.appID = this.$getAppID();
    if(this.appID)
      this.appName = this.$getAppName()
    }
    else
      this.appName="智能精准灌溉平台"
  },
  methods: {
    gotoFirstPage() {
      this.$setAppID(0);
      this.$setAppName('');
      window.location.href = '/#/first/app'
    },
    onPersonCenter() {
      this.$router.push({path:'/first/user'})
    },
    onMyApp() {
      this.$router.push({path:'/first/app'})
    },
    logout() {
      this.$secret
        .logout()
        .then(() => {
          this.$router.replace({ path: '/Login' })
        })
        .catch(() => {
          this.$router.replace({ path: '/Login' })
        })
    }
  },
}
</script>
