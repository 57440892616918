//import mqtt from 'mqtt'
import api from '@/api/api.js'
export default class MqttClient {
  client
  topic
  connected = false
  subscribe(topic, onMessage) {
    api.GetMsgSvrInfo().then(msgSvrInfo => {
      if (this.topic) this.unsubscribe()
      this.client = mqtt.connect(msgSvrInfo.Url, {
        clean: true,
        clientId: 'web' + Math.random().toString(16).substring(2),
        username: msgSvrInfo.User,
        password: msgSvrInfo.Pass,
        keepalive: 50 //默认60将会导致不断重连
      })
      this.client.on('connect', _ => {
        console.log('消息服务已连接')
        if (!this.connected) {
          this.client.subscribe(topic)
          console.log(`订阅${topic}`)
          this.topic = topic
          this.connected = true
        }
      })
      this.client.on('reconnect', () => {
        console.log('reconnect')
      })
      this.client.on('disconnect', () => {
        console.log('disconnect')
      })
      this.client.on('message', (topic, message) => {
        message = JSON.parse(message)
        console.log('收到', message)
        if (onMessage) onMessage(topic, message)
      })
    })
  }
  unsubscribe() {
    if (this.connected && this.topic) {
      console.log('退订')
      this.client.unsubscribe(this.topic)
      this.topic = null
      this.client.end()
    }
  }
  send(topic, msg) {
    if (this.connected) this.client.publish(topic, msg)
    else console.log('mqtt还未连接')
  }
}
