import layout from '@/view/layout'
const menus = [
  {
    path: '',
    redirect: '/first',
    hiddenMenu: true
  },
  {
    path: '/login',
    component: () => import('@/view/account/login'),
    hiddenMenu: true
  },
  {
    path: '/wechart',
    component: () => import('@/view/account/wechart'),
    hiddenMenu: true
  },
  {
    path: '/video/:id/:type/:deviceId/:channel',
    component: () => import('@/view/video'),
    hiddenMenu: true,
    name: 'videoPlayer'
  },
  { path: '/404', component: () => import('@/view/404'), hidden: true },
  {
    path: '/sqls',
    component: () => import('@/view/sqls'),
    hiddenMenu: true
  },
  {
    path: '/first',
    meta: {
      title: '我的应用',
      icon: 'app'
    },
    component: () => import('@/view/layout/singlePage'),
    hiddenMenu: true,
    redirect: '/first/app',
    children: [
      {
        path: 'app',
        component: () => import('@/view/app')
      },
      {
        path: 'map',
        component: () => import('@/view/map')
      },
      {
        path: 'city/:addressCode',
        component: () => import('@/view/map/city'),
        name: 'cityMap'
      },
      {
        path: 'user',
        meta: {
          title: '个人中心',
          icon: 'user'
        },
        name: 'user',
        component: () => import('@/view/user')
      }
    ]
  },
  {
    path: '/data',
    meta: {
      title: '单元展示',
      icon: 'farm'
    },
    component: layout,
    redirect: '/data/chart',
    children: [
      {
        path: 'chart',
        meta: {
          title: '单元图表',
          icon: 'record'
        },
        name: 'tentacleChart',
        component: () => import('@/view/data/panel/chart')
      },
      {
        path: 'list',
        meta: {
          title: '单元面板',
          icon: 'dashboard'
        },
        name: 'layoutChart',
        component: () => import('@/view/data/panel')
      },
      {
        path: 'tentacle',
        meta: {
          title: '单元数据',
          icon: 'data'
        },
        name: 'tentacleData',
        component: () => import('@/view/data/tentacle')
      }
    ]
  },

  {
    path: '/business',
    meta: {
      title: '业务操作',
      icon: 'business'
    },
    component: layout,
    redirect: '/business/switchJob',
    children: [
      {
        path: 'switchPlan',
        meta: {
          title: '周期计划',
          icon: 'plan'
        },
        name: 'switchPlan',
        component: () => import('@/view/business/switchPlan')
      },
      {
        path: 'switchJob',
        meta: {
          title: '开关命令',
          icon: 'job'
        },
        name: 'switchJob',
        component: () => import('@/view/business/switchJob')
      },
      {
        path: 'switchRecord',
        meta: {
          title: '开关日志',
          icon: 'record'
        },
        name: 'switchRecord',
        component: () => import('@/view/business/switchRecord')
      },
      // {
      //   path: 'switchs',
      //   meta: {
      //     title: '开关列表',
      //     icon: 'switch'
      //   },
      //   name: 'Switchs',
      //   component: () => import('@/view/business/switchs')
      // },
      {
        path: 'scene',
        meta: {
          title: '自动化',
          icon: 'robot'
        },
        name: 'scene',
        component: () => import('@/view/business/scene')
      }
    ]
  },
  {
    path: '/maintance',
    meta: {
      title: '系统设置',
      icon: 'maintance'
    },
    component: layout,
    redirect: 'maintance/panel',
    children: [
      {
        path: 'panel',
        meta: {
          title: '单元管理',
          icon: 'table'
        },
        name: 'panel',
        component: () => import('@/view/maintance/panel')
      },

      // {
      //   path: 'layout',
      //   meta: {
      //     title: '图表布局',
      //     icon: 'layout'
      //   },
      //   name: 'editLayout',
      //   component: () => import('@/view/maintance/editLayout')
      // },
      {
        path: 'device',
        meta: {
          title: '设备管理',
          icon: 'device'
        },
        name: 'device',
        component: () => import('@/view/maintance/device')
      },
      {
        path: 'tentacle',
        meta: {
          title: '通道管理',
          icon: 'tentacle'
        },
        name: 'tentacle',
        component: () => import('@/view/maintance/tentacle')
      },
      {
        path: 'video',
        meta: {
          title: '视频管理',
          icon: 'video'
        },
        name: 'videoList',
        component: () => import('@/view/maintance/video')
      },
      {
        path: 'videoSource',
        meta: {
          title: '视频平台',
          icon: 'video_source'
        },
        name: 'videoSource',
        component: () => import('@/view/maintance/videoSource')
      },
      {
        path: 'calibration',
        meta: {
          title: '数据校准',
          icon: 'calibration'
        },
        name: 'calibration',
        component: () => import('@/view/maintance/calibration')
      },
      {
        path: 'valueFormatter',
        meta: {
          title: '个性化显示',
          icon: 'format'
        },
        name: 'valueFormatter',
        component: () => import('@/view/maintance/valueFormatter')
      }

      // {
      //   path: 'camera',
      //   meta: {
      //     title: '摄像机',
      //     icon: 'camera'
      //   },
      //   name: 'camera',
      //   component: () => import('@/view/camera')
      // }
    ]
  },
  { path: '*', redirect: '/404', hiddenMenu: true }
]
export default menus
