import axios from 'axios'
import qs from 'qs'
import md5 from '../utils/md5'
import XDate from 'xdate'
import des from '../utils/des'

export const ServerRoot = window.config.ServerRoot;
export const APIPrefix = window.config.APIPrefix;
export const APIRoot = ServerRoot + APIPrefix;


axios.defaults.baseURL = APIRoot;
// axios.interceptors.request.use(
//     config => {
//         config.headers.Authorization = 'mytoken';
//         return config;
//     },
//     err => {
//         return Promise.reject(err);
//     }
// );

export const IsLogin = () => {
	let last = localStorage.getItem('LastActiveTime');
	if (last) {
		let lasttime = new XDate(last);
		let now = new XDate();
		return lasttime.addMinutes(10) < now;
	}
	return false;
};
const convertDateTimeToStr = (params) => {
	if(params==null)
		return null;
  if (params instanceof Array) {
    return params.map((x) => {
      return convertDateTimeToStr(x);
    });
  } else if (params instanceof Date) {
    return new XDate(params).toString("yyyy-MM-dd HH:mm:ss");
  } else if (params instanceof Object) {
    let keys = Object.keys(params);
    keys.forEach((x) => {
      params[x] = convertDateTimeToStr(params[x]);
    });
    return params;
  } else return params;
};
export const getQueryString = (urlParams) => {
	if (!urlParams)
		urlParams = {};
	let formatParams = convertDateTimeToStr({...urlParams})
	let ts = nowToTS();
	formatParams.ts = ts;
	formatParams.pp = localStorage.getItem('PP');
	let pstr = qs.stringify(formatParams, { arrayFormat: 'brackets' });
	let key = localStorage.getItem('Key');
	let sign = md5(pstr + key);
	pstr = pstr + '&sign=' + sign;
	return pstr;
};
export const doExec = (url, urlParams, data, method) => {
	let pstr = getQueryString(urlParams);
	return axios({ url: url + '?' + pstr, params: null, data: convertDateTimeToStr(data), method: method })
		.then((res) => {
			localStorage.setItem('LastActiveTime',new Date());
			return res.data;
		});
};
export const local = () => {
	let ret = "";
	let e = navigator;
	for (var prop in e) {
		let val = e[prop];
		let t = typeof (val);
		if (t == 'object') {
		}
		else if (t === "function") {
		}
		else {
			ret += prop + val;
		}
		ret += ";";
	}
	return md5(ret);
};
export const tsToDateTime = (ts) => {
	if (ts.length < 14)
		alert('日期时间格式位数少于14位！');
	let y = ts.substr(0, 4);
	let m = ts.substr(4, 2) - 1;
	let d = ts.substr(6, 2);
	let hh = ts.substr(8, 2);
	let mm = ts.substr(10, 2);
	let ss = ts.substr(12, 2);
	let x = new XDate(y, m, d, hh, mm, ss, 0);
	return x.toDate();
}
export const tsToDate = (ts) => {
	if (ts.length < 8)
		alert('日期时间格式位数少于8位！')
	let y = ts.substr(0, 4);
	let m = ts.substr(4, 2) - 1;
	let d = ts.substr(6, 2);
	return new XDate(y, m, d).toDate();
}

export const dateTimeToTS = (d) => {
	return new XDate(d).toString('yyyyMMddHHmmss')
}

export const dateToTS = (d) => {
	return new XDate(d).toString('yyyyMMdd')
}

export const formatTS = (ts) => {
	if (!ts || (ts.length != 8 && ts.length != 14))
		return '';
	else if (ts.length == 8) {
		var d = tsToDate(ts);
		return XDate(d).toString('yyyy-MM-dd');
	}
	else {
		var d = tsToDateTime(ts);
		return XDate(d).toString('yyyy-MM-dd HH:mm:ss');
	}
}
export const nowToTS = () => {
	return dateTimeToTS(new Date());
}
export const http = axios;

