export const YesNo = [
  { value: 1, text: '有效' },
  { value: 2, text: '无效' }
]
export const UserRoles = [{ value: 1, text: '普通' }]
export const UserKinds = [
  { value: 1, text: '普通' },
  { value: 2, text: '系统' }
]
export const MsgKind = [
  { value: 1, text: '上行请求' },
  { value: 2, text: '下行应答' },
  { value: 3, text: '下行命令' },
  { value: 4, text: '上行答复' }
]
export const TerminalLogKind = [
  { value: 1, text: '调试' },
  { value: 2, text: '异常' }
]

export const DeliverStatus = [
  { value: 1, text: '未投递' },
  { value: 2, text: '成功' },
  { value: 3, text: '失败' },
  { value: 4, text: '重试中' }
]
export const SysLogType = [
  { value: 1, text: '调试' },
  { value: 2, text: '轨迹' },
  { value: 3, text: '错误' },
  { value: 4, text: '异常' }
]
export const Direction = [
  { value: 1, text: '上行' },
  { value: 2, text: '下行' },
  { value: 3, text: '双向' }
]
export const MessageStatus = [
  { value: 1, text: '进行中' },
  { value: 2, text: '完成' },
  { value: 3, text: '失败' }
]
export const TerminalStatus = [
  { value: 1, text: '未启用' },
  { value: 2, text: '配置中' },
  { value: 3, text: '正常' },
  { value: 4, text: '离线' },
  { value: 5, text: '无效' }
]
export const Performances = [
  { value: 1, text: '锐利' },
  { value: 2, text: '平滑' }
]
export const DataValueTypes = [
  { value: 1, text: '模拟量' },
  { value: 2, text: '开关量' }
]
export const ChartKinds = [
  { value: 1, text: '曲线图' },
  { value: 2, text: '仪表图' },
  { value: 3, text: '开关图' },
  { value: 4, text: '数字' }
]
export const DataStatus = [
  { value: 1, text: '有效' },
  { value: 2, text: '无效' }
]
export const Every = [
  {
    value: '5m',
    text: '5分钟'
  },
  { value: '10m', text: '10分钟' },
  { value: '30m', text: '30分钟' },
  { value: '1h', text: '1小时' },
  { value: '3h', text: '3小时' },
  { value: '6h', text: '6小时' },
  { value: '1d', text: '1天' },
  { value: '7d', text: '7天' },
  { value: '1mo', text: '1月' }
]
export const Method = [
  {
    value: 'mean',
    text: '算术平均'
  },
  { value: 'sum', text: '和' },
  { value: 'integral', text: '积分' },
  { value: 'median', text: '中间值' },
  { value: 'spread', text: '最大差值' },
  { value: 'stddev', text: '标准方差' },
  { value: 'max', text: '最大' },
  { value: 'first', text: '第一个' },
  { value: 'last', text: '最后一个' }
]
export const SwitchJobStatus = [
  { value: 1, text: '已创建' },
  { value: 2, text: '同步中' },
  { value: 3, text: '已同步' },
  { value: 4, text: '同步失败' },
  { value: 5, text: '已取消' }
]
export const SwitchPlanStatus = [
  {
    value: true,
    text: '已启用'
  },
  {
    value: false,
    text: '已禁用'
  }
]
export const IsVirtual = [
  {
    value: true,
    text: '虚拟通道'
  },
  {
    value: false,
    text: '物理通道'
  }
]
export const ComputeMethod = [
  { value: 1, text: '平均值' },
  { value: 2, text: '最大值' },
  { value: 3, text: '最小值' },
  { value: 4, text: '多数值' },
  { value: 5, text: '自定义' },
  { value: 6, text: '前后差值' },
  { value: 7, text: '累加' },
]
export const Comparison = [
  { value: 1, text: '等于' },
  { value: 2, text: '不等于' },
  { value: 3, text: '大于' },
  { value: 4, text: '大于等于' },
  { value: 5, text: '小于' },
  { value: 6, text: '小于等于' }
]
export const VideoType = [
  {
    value: 1,
    text: '萤石云'
  },
  {
    value: 2,
    text: '乐橙'
  }
]
export const valueToStr = (dict, value) => {
  if (!dict || !dict.length) {
    alert('字典为空！')
    return ''
  }
  // let v=parseInt(value);
  let v = value
  for (let i = 0; i < dict.length; i++) {
    if (dict[i].value == v) return dict[i].text
  }
  return '未知'
}
