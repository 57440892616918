import { doExec } from '@/api/request'
export default {
  GetMsgSvrInfo() {
    return doExec('Account/MsgSvrInfo', {}, {}, 'GET')
  },
  DataType: {
    /*获取所有数据类型*/
    List(includes) {
      return doExec('DataType/List', { includes }, {}, 'GET')
    } /*搜索数据类型*/,
    Search(condition) {
      return doExec('DataType/Search', condition, {}, 'GET')
    } /*获取数据类型信息*/,
    Get(typeCode, includes) {
      return doExec('DataType', { typeCode, includes }, {}, 'GET')
    } /*增加传感器类型*/,
    Add(form) {
      return doExec('DataType', {}, form, 'POST')
    } /*修改数据类型信息*/,
    Modify(typeCode, form) {
      return doExec('DataType', { typeCode }, form, 'PUT')
    } /*删除数据类型*/,
    Delete(typeCode) {
      return doExec('DataType', { typeCode }, {}, 'DELETE')
    }
  },
  Address: {
    GetTree() {
      return doExec(
        'Address/Tree',
        {
          
        },
        {},
        'GET'
      )
    },
    Get(addressCode, includes) {
      return doExec(
        'Address',
        {
          addressCode,
          includes
        },
        {},
        'GET'
      )
    },
    Add(form) {
      return doExec('Address', {}, form, 'POST')
    },
    Modify(addressCode, form) {
      return doExec(
        'Address',
        {
          addressCode
        },
        form,
        'PUT'
      )
    },
    Delete(addressCode) {
      return doExec(
        'Address',
        {
          addressCode
        },
        {},
        'DELETE'
      )
    },
    Children(addressCode, includes) {
      return doExec(
        'Address/Children',
        {
          addressCode,
          includes
        },
        {},
        'GET'
      )
    }
  },

  App: {
    /*获取指定App的信息*/
    Get(id, includes) {
      return doExec('App', { id, includes }, {}, 'GET')
    } /*获取所有系统信息*/,
    List(condition) {
      return doExec('App/List', condition, {}, 'GET')
    } /*注册应用系统*/,
    Add(form) {
      return doExec('App', {}, form, 'POST')
    } /*更新应用系统信息*/,
    Modify(id, form) {
      return doExec('App', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('App', { id }, null, 'DELETE')
    },
    /*禁用应用系统*/
    Disable(id) {
      return doExec('App/Disable', { id }, {}, 'POST')
    } /*启用应用系统*/,
    Enable(id) {
      return doExec('App/Enable', { id }, {}, 'POST')
    },
    Attend(code) {
      return doExec('App/Attend', {}, { code }, 'POST')
    },
    Leave(id, userID) {
      return doExec('App/Leave', { id }, { userID }, 'POST')
    },
    SetManager(id, userID) {
      return doExec('App/SetUser', { id }, { userID, IsMaster: true }, 'POST')
    },
    SetNormalUser(id, userID) {
      return doExec('App/SetUser', { id }, { userID, IsMaster: false }, 'POST')
    },
    GetRootAddressAppCount(status){
      return doExec('App/RootAddressAppCount', {status}, {}, 'GET')
    },
    GetChildrenAddressAppCount(parent,status){
      return doExec('App/ChildrenAddressAppCount', {parent,status}, {}, 'GET')
    },
    GetChildrenAddressAppAreaCount(parent,status){
      return doExec('App/ChildrenAddressAppAreaCount', {parent,status}, {}, 'GET')
    },
    Search(condition) {
      return doExec('App/Search', condition, {}, 'GET')
    },
  },

  Panel: {
    Get(id, includes) {
      return doExec('Panel', { id, includes }, {}, 'GET')
    },
    Add(form) {
      return doExec('Panel', {}, form, 'POST')
    },
    JoinTentacle(id, form) {
      return doExec('Panel/JoinTentacle', { id }, form, 'POST')
    },
    LeaveTentacle(id, form) {
      return doExec('Panel/LeaveTentacle', { id }, form, 'POST')
    },
    Modify(id, form) {
      return doExec('Panel', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('Panel', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('Panel/Search', condition, {}, 'GET')
    },
    List(condition) {
      return doExec('Panel/List', condition, null, 'GET')
    }
  },
  Device: {
    /*获取数据配置项*/
    Get(id, includes) {
      return doExec('Device', { id, includes }, {}, 'GET')
    } /*增加传感器数据种类配置*/,
    Add(form) {
      return doExec('Device', {}, form, 'POST')
    } /*修改传感器数据种类配置信息*/,
    Bind(id, form) {
      return doExec('Device/Bind', { id }, form, 'POST')
    } /*删除传感器数据种类配置信息*/,
    Unbind(id) {
      return doExec('Device/Unbind', { id }, null, 'POST')
    },
    UpdateLabel(id, label) {
      return doExec('Device/UpdateLabel', { id }, { label }, 'POST')
    },
    Delete(id) {
      return doExec('Device', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('Device/Search', condition, {}, 'GET')
    },
    List(condition) {
      return doExec('Device/List', condition, null, 'GET')
    },
    Exists(condition) {
      return doExec('Device/Exists', condition, null, 'GET')
    }
  },
  Tentacle: {
    /*获取数据配置项*/
    Get(id, includes) {
      return doExec('Tentacle', { id, includes }, {}, 'GET')
    } /*增加传感器数据种类配置*/,
    Delete(id) {
      return doExec('Tentacle', { id }, {}, 'DELETE')
    },

    List(condition) {
      return doExec('Tentacle/List', condition, null, 'GET')
    },
    Show(id) {
      return doExec('Tentacle/Show', { id }, {}, 'POST')
    },
    Hide(id) {
      return doExec('Tentacle/Hide', { id }, {}, 'POST')
    },
    UpdateLabel(id, label) {
      return doExec('Tentacle/UpdateLabel', { id }, { label }, 'POST')
    },
    UpdateMax(id, max) {
      return doExec('Tentacle/UpdateMax', { id }, { max }, 'POST')
    },
    UpdateMin(id, min) {
      return doExec('Tentacle/UpdateMin', { id }, { min }, 'POST')
    },
    UpdateCalibration(id, calibrationID) {
      return doExec('Tentacle/UpdateCalibration', { id }, { calibrationID }, 'POST')
    },
    UpdateValueFormatter(id, valueFormatterID) {
      return doExec('Tentacle/UpdateValueFormatter', { id }, { valueFormatterID }, 'POST')
    },
    UpdateAlertLowerLimit(id, value) {
      return doExec('Tentacle/UpdateAlertLowerLimit', { id }, { value }, 'POST')
    },
    UpdateAlertHigherLimit(id, value) {
      return doExec('Tentacle/UpdateAlertHigherLimit', { id }, { value }, 'POST')
    },
    Search(condition) {
      return doExec('Tentacle/Search', condition, null, 'GET')
    },
    Combine(form) {
      return doExec('Tentacle/Combine', {}, form, 'POST')
    },
    TestCombine(source, atTime, value) {
      return doExec('Tentacle/TestCombine', { source, atTime, value }, {}, 'GET')
    },
    ReCombine(id, form) {
      return doExec('Tentacle/ReCombine', { id }, form, 'POST')
    }
  },
  Calibration: {
    Get(id, includes) {
      return doExec('Calibration', { id, includes }, {}, 'GET')
    },
    Add(form) {
      return doExec('Calibration', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('Calibration', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('Calibration', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('Calibration/Search', condition, {}, 'GET')
    },
    List(includes) {
      return doExec('Calibration/List', null, { includes }, 'GET')
    },
    Test(id, form) {
      return doExec('Calibration/Test', { id }, form, 'POST')
    }
  },
  ValueFormatter: {
    Get(id, includes) {
      return doExec('ValueFormatter', { id, includes }, {}, 'GET')
    },
    Add(form) {
      return doExec('ValueFormatter', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('ValueFormatter', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('ValueFormatter', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('ValueFormatter/Search', condition, {}, 'GET')
    },
    List(condition) {
      return doExec('ValueFormatter/List', condition, { }, 'GET')
    }
  },
  VideoSource: {
    Get(id, includes) {
      return doExec('VideoSource', { id, includes }, {}, 'GET')
    },
    Add(form) {
      return doExec('VideoSource', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('VideoSource', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('VideoSource', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('VideoSource/Search', condition, {}, 'GET')
    },
    List(condition) {
      return doExec('VideoSource/List', condition, { }, 'GET')
    }
  },
  Video: {
    Get(id, includes) {
      return doExec('Video', { id, includes }, {}, 'GET')
    },
    Add(form) {
      return doExec('Video', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('Video', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('Video', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('Video/Search', condition, {}, 'GET')
    },
    List(condition) {
      return doExec('Video/List', condition, null, 'GET')
    }
  },
  YSYVideo: {
    GetToken(id) {
      return doExec('YSYVideo/GetToken', {id}, {}, 'GET')
    },
  },
  LCVideo: {
    GetToken(id) {
      return doExec('LCVideo/GetToken', {id}, {}, 'GET')
    },
  },
  Scene: {
    Get(id) {
      return doExec('Scene', { id }, null, 'GET')
    },
    Add(form) {
      return doExec('Scene', null, form, 'POST')
    },
    Modify(id, form) {
      return doExec('Scene', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('Scene', { id }, null, 'DELETE')
    },
    Search(condition) {
      return doExec('Scene/Search', condition, null, 'GET')
    },
    ListTimer(id, includes) {
      return doExec('Scene/Timers', { id, includes }, null, 'GET')
    },
    ListTrigger(id, includes) {
      return doExec('Scene/Triggers', { id, includes }, null, 'GET')
    },
    ListAction(id, includes) {
      return doExec('Scene/Actions', { id, includes }, null, 'GET')
    },
    Enable(id) {
      return doExec('Scene/Enable', { id }, null, 'POST')
    },
    Disable(id) {
      return doExec('Scene/Disable', { id }, null, 'POST')
    }
  },
  SceneTimer: {
    Get(id, includes) {
      return doExec('SceneTimer', { id, includes }, null, 'GET')
    },
    Add(form) {
      return doExec('SceneTimer', null, form, 'POST')
    },
    Modify(id, form) {
      return doExec('SceneTimer', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('SceneTimer', { id }, null, 'DELETE')
    }
  },
  SceneTrigger: {
    Get(id, includes) {
      return doExec('SceneTrigger', { id, includes }, null, 'GET')
    },
    Add(form) {
      return doExec('SceneTrigger', null, form, 'POST')
    },
    Modify(id, form) {
      return doExec('SceneTrigger', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('SceneTrigger', { id }, null, 'DELETE')
    }
  },
  SceneAction: {
    Get(id, includes) {
      return doExec('SceneAction', { id, includes }, null, 'GET')
    },
    Add(form) {
      return doExec('SceneAction', null, form, 'POST')
    },
    Modify(id, form) {
      return doExec('SceneAction', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('SceneAction', { id }, null, 'DELETE')
    }
  },
  SwitchPlan: {
    /*数据搜索*/
    Get(id) {
      return doExec('SwitchPlan', { id }, {}, 'GET')
    },
    /*数据搜索*/
    Search(condition) {
      return doExec('SwitchPlan/Search', condition, {}, 'GET')
    },
    Enable(id) {
      return doExec('SwitchPlan/Enable', { id }, null, 'POST')
    },
    Disable(id) {
      return doExec('SwitchPlan/Disable', { id }, null, 'POST')
    },
    Modify(id, form) {
      return doExec('SwitchPlan', { id }, form, 'PUT')
    },
    Add(form) {
      return doExec('SwitchPlan', null, form, 'POST')
    },
    Delete(id) {
      return doExec('SwitchPlan', { id }, null, 'DELETE')
    }
  },
  SwitchJob: {
    /*数据搜索*/
    Search(condition) {
      return doExec('SwitchJob/Search', condition, {}, 'GET')
    },
    Add(form) {
      return doExec('SwitchJob', null, form, 'POST')
    },
    Delete(id) {
      return doExec('SwitchJob', { id }, null, 'DELETE')
    }
  },
  SwitchRecord: {
    /*数据搜索*/
    Search(condition) {
      return doExec('SwitchRecord/Search', condition, {}, 'GET')
    }
  },
  User: {
    Get(id) {
      return doExec('User', { id }, {}, 'GET')
    },
    Add(form) {
      return doExec('User', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('User', { id }, form, 'PUT')
    },
    Delete(id) {
      return doExec('User', { id }, {}, 'DELETE')
    },
    Search(condition) {
      return doExec('User/Search', condition, {}, 'GET')
    },
    Deblocking(id) {
      return doExec('User/Deblocking', { id }, {}, 'POST')
    },
    ResetPass(id) {
      return doExec('User/ResetPass', { id }, {}, 'POST')
    },
    List(role) {
      return doExec('User/List', { role }, {}, 'GET')
    },
    Right: {
      List(id) {
        return doExec('User/Right/List', { id }, {}, 'GET')
      },
      Update(id, rights) {
        return doExec('User/Right/Update', { id }, rights, 'PUT')
      }
    },
    Action: {
      List(id) {
        return doExec('User/Action/List', { id }, {}, 'GET')
      }
    },
    ModifyName(form) {
      return doExec('User/Name', {}, form, 'PUT')
    },
    ModifyAccount(form) {
      return doExec('User/Account', {}, form, 'PUT')
    },
    ModifyPass(form) {
      return doExec('User/Pass', {}, form, 'PUT')
    }
  },
  Right: {
    Search(condition) {
      return doExec('Right/Search', condition, {}, 'GET')
    },
    Add(form) {
      return doExec('Right', {}, form, 'POST')
    },
    Modify(rightCode, form) {
      return doExec('Right', { rightCode }, form, 'PUT')
    },
    Delete(rightCode) {
      return doExec('Right', { rightCode }, {}, 'DELETE')
    },
    Get(rightCode) {
      return doExec('Right', { rightCode }, {}, 'GET')
    },
    List() {
      return doExec('Right/List', {}, {}, 'GET')
    },
    Action: {
      Add(form) {
        return doExec('Right/Action', {}, form, 'POST')
      },
      Modify(actionID, form) {
        return doExec('Right/Action', { actionID }, form, 'PUT')
      },
      Delete(actionID) {
        return doExec('Right/Action', { actionID }, {}, 'DELETE')
      },
      List(rightCode) {
        return doExec('Right/Action/List', { rightCode }, {}, 'GET')
      },
      Get(actionID) {
        return doExec('Right/Action', { actionID }, {}, 'GET')
      }
    }
  },

  Data: {
    /*数据搜索*/
    Search(condition) {
      return doExec('Data/Search', condition, {}, 'GET')
    },
    Integral(condition) {
      return doExec('Data/Integral', condition, {}, 'GET')
    },
    Current(terminalID, tentacleID) {
      return doExec('Data/Current', { tentacleID, terminalID }, {}, 'GET')
    }
  },
  ChartLayout: {
    Get(id, includes) {
      return doExec('ChartLayout', { id, includes }, {}, 'GET')
    },
    Search(condition) {
      return doExec('ChartLayout/Search', condition, {}, 'GET')
    },
    Add(form) {
      return doExec('ChartLayout', {}, form, 'POST')
    },
    Modify(id, form) {
      return doExec('ChartLayout', { id }, form, 'PUT')
    },
    UpdatePosition(id, form) {
      return doExec('ChartLayout/UpdatePosition', { id }, form, 'POST')
    },
    Delete(id) {
      return doExec('ChartLayout', { id }, {}, 'DELETE')
    },
    List(appID, includes) {
      return doExec('ChartLayout/List', { appID, includes }, {}, 'GET')
    },
    Item: {
      Add(form) {
        return doExec('ChartLayout/Item', {}, form, 'POST')
      },
      Delete(id) {
        return doExec('ChartLayout/Item', { id }, {}, 'DELETE')
      },
      Get(id, includes) {
        return doExec('ChartLayout/Item', { id, includes }, {}, 'GET')
      },
      List(ChartLayoutID, includes) {
        return doExec('ChartLayout/Item/List', { ChartLayoutID, includes }, {}, 'GET')
      },
      Modify(id, form) {
        return doExec('ChartLayout/Item', { id }, form, 'PUT')
      }
    }
  },
  Invitation: {
    Add(appID) {
      return doExec('Invitation', null, { appID }, 'POST')
    },
    Delete(id) {
      return doExec('Invitation', { id }, null, 'DELETE')
    }
  }
}
