import * as secret from '@/api/secret';
import api from '@/api/api';
import * as request from '@/api/request';
import * as dict from '@/dict';
import JSON5 from 'json5'
import Vue from 'vue'
import XDate from 'xdate'
let ServerMixin={}
const rights = JSON5.parse(localStorage.getItem('Rights'));
ServerMixin.install = function (v, options) {
    v.prototype.$EventBus = new Vue()
    v.prototype.$api = api;
    v.prototype.$secret = secret;
    v.prototype.$request = request;
    v.prototype.$dict = dict;
    v.prototype.$getAppID=()=>{
        let appID = sessionStorage.getItem('appID');
        if(appID==undefined || appID==null || appID=='0' || appID==0)
            window.location.href = '/#/first/app'
        return parseInt(appID);
    }
    v.prototype.$setAppID=(appID)=>{
        sessionStorage.setItem('appID',appID);
    }
    v.prototype.$getAppName=()=>{
        let appName = sessionStorage.getItem('appName');
        if(appName==undefined || appName==null || appName=='')
            window.location.href = '/#/first/app'
        return appName;
    }
    v.prototype.$setAppName=(appName)=>{
        sessionStorage.setItem('appName',appName);
    }
    v.prototype.$isAllow = (url, method) => {
        let r = true;
        url = request.APIPrefix + url.replace(/(^\s*)|(\s*$)/g, '').toLowerCase();
        for (let index in rights) {
            if (rights[index].Method == method && url.search(rights[index].Action) == 0) {
                r = rights[index].HasRight;
                break;
            }
        }
        return r;
    };
    v.prototype.$handleApiError = (error) => {
        console.log('错误发生');
        console.log(error);
        let str = '';
        if (error.response && error.response.status && error.response.status == 401) {
            location.href = '/#/login';
        }
        else {
            if (error.response) {
                // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                str = '服务器返回错误：' + error.response.status + ',' + (error.response.title || error.response.data.msg || error.response.data);
            } else {
                str = error.message;
            }
            v.prototype.$confirm(str, '错误', {
                confirmButtonText: '确定',
                type: 'error',
                showCancelButton: false,
                closeOnPressEscape: true
            })
                .then(() => { })
                .catch(() => { });
        }
    };
    v.prototype.$$notify = (axios, success_callback, successText, fail_callback) => {
        axios.then((res) => {
            if (success_callback) {
                success_callback(res);
            }
            if (successText) {
                v.prototype.$notify({
                    message: successText,
                    type: 'success'
                });
            }
        }).catch((err) => {
            if (fail_callback)
                fail_callback(err);
            v.prototype.$handleApiError(err);
        });
    };
    v.prototype.$NONE = () => { };
    v.prototype.$updateArray = (arr, fieldName, fieldValue, newRow, defaultAppand) => {
        let found = null;
        //先查找是否有同样的主键（key），如果有，说明是修改。这里主键暂时不支持复合主键
        for (let i in arr) {
            if (arr[i][fieldName] == fieldValue) {
                for (let j in arr[i])
                    v.prototype.$set(arr[i], j, newRow[j]);
                found = arr[i];
                break;
            }
        }
        //没有找到，说明是新增的记录
        if (!found) {
            if (defaultAppand) {
                arr.push(newRow);
                found = newRow;
            }
            else
                alert('本地没有发现要更新的记录！');
        }
        return found;
    };
    v.prototype.$removeArray = (arr, fieldName, fieldValue) => {
        for (let i in arr) {
            if (arr[i][fieldName] == fieldValue) {
                arr.splice(i, 1);
                break;
            }
        }
    };
    v.prototype.$convert = {
        formatDateTime(d, formatString) {
            if (d) {
                let xd = new XDate(d);
                return xd.toString(formatString);
            }
            return '';
        },
        dateToStr(d) {
            if (d) {
                let xd = new XDate(d);
                return xd.toString('yyyy-MM-dd');
            }
            return '';
        },
        datetimeToStr(d) {
            if (d) {
                let xd = new XDate(d);
                return xd.toString('yyyy-MM-dd HH:mm:ss');
            }
            return '';
        },
        secondsToStr(seconds, mask) {
            mask = mask || 'dhms'
            let result = ''
            if (seconds >= 86400 && mask.indexOf('d') != -1) {
              result += Math.floor(seconds / 86400) + '天'
              seconds = seconds % 86400
            }
            if (seconds >= 3600 && mask.indexOf('h') != -1) {
              result += Math.floor(seconds / 3600) + '小时'
              seconds = seconds % 3600
            }
            if (seconds >= 60 && mask.indexOf('m') != -1) {
              result += Math.floor(seconds / 60) + '分钟'
              seconds = seconds % 60
            }
            if (seconds > 0 && mask.indexOf('s') != -1) {
              result += seconds + '秒'
            } else if (result == '') result = '0秒'
            return result
          }
    };
    v.prototype.$verify = {
        isJson(str) {
          if (typeof str == 'string') {
            try {
              var obj = JSON.parse(str)
              if (typeof obj == 'object' && obj) {
                return true
              } else {
                return false
              }
            } catch (e) {
              console.log('error：' + str + '!!!' + e)
              return false
            }
          }
          return false
        },
        checkJson(rule, value, callback) {
          if (!value) callback()
          if (v.prototype.$verify.isJson(value)) {
            callback()
            return
          } else {
            callback(new Error('JSON格式错误'))
          }
        },
        checkTerminalId(rule, value, callback) {
          if (!value) callback()
          v.prototype.$api.Device.Exists({appID:v.prototype.$getAppID(),terminalID:value})
            .then(found => {
              if (found) callback()
              else callback(new Error('终端编号不正确！'))
            })
            .catch(this.$handleApiError)
        }
      }
}
export default ServerMixin;