<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive :include="cachedViews">
      <router-view style="overflow:hidden auto;width:100%;padding:10px;"></router-view>
    </keep-alive>
  </transition>
</template>
<script>
import MqttClient from '@/utils/sysMsg'
export default {
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  },
  data(){
    return {
      mqtt:null,
      appID:this.$getAppID(),
    }
  },
  created(){
    this.mqtt  = new MqttClient()
  },
  beforeDestroy(){
    this.mqtt.unsubscribe()
  },
  methods:{
    connectMqtt(){
      this.mqtt.subscribe(`iot/customer/data/${this.appID}/#`, (topic, message) => {
        this.$EventBus.$emit('data',message)
      })
    },
  }
}
</script>



